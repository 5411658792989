
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IUsuario, IUsuarioPrivilegio } from '@/models/Entidades/IUsuario';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import CampoTelefone from '@/core/components/UI/CampoTelefone.vue';
import CampoEmail from '@/core/components/UI/CampoEmail.vue';
import CampoSenha from '@/core/components/UI/CampoSenha.vue';
import SelecionarSimNao from '@/core/components/UI/SelecionarSimNao.vue';
import SelecionarData from '@/core/components/UI/SelecionarData.vue';
import ServicoUsuario from '@/servicos/ServicoUsuario';
import { IResposta } from '@/core/models/IResposta';
import listaPadraoprivilegiosUsuario, { IDTOUsuarioPrivilegio } from '@/models/DTO/Usuarios/IDTOUsuarioPrivilegio';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'UsuarioCadastro',
  components: {
    ComunicacaoApi,
    CampoEmail,
    CampoTelefone,
    CampoSenha,
    SelecionarSimNao,
    SelecionarData,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarRespostaRedirecionamento, obterCodigoRota,
    } = useAppBase();
    const servicoUsuario = new ServicoUsuario();

    const state = reactive({
      usuario: {} as IUsuario,
      privilegios: [] as IDTOUsuarioPrivilegio[],
    });

    async function obterUsuario(codigo:number) {
      appBase.carregando = true;
      state.usuario = await servicoUsuario.obter(codigo);
      if (UtilitarioGeral.validaLista(state.usuario.privilegios)) {
        state.usuario.privilegios.forEach((usuarioPrivilegio) => {
          const index = state.privilegios.findIndex((c) => c.identificador === usuarioPrivilegio.privilegio);
          if (index >= 0) {
            state.privilegios[index].valor = true;
          }
        });
      }
      appBase.carregando = false;
    }

    function limparDados() {
      state.usuario = {} as IUsuario;
      state.usuario.codigo = 0;
      state.usuario.status = true;
      state.usuario.confirmado = true;
      state.usuario.privilegios = [];
      state.privilegios = UtilitarioGeral.instanciaObjetoLocal(listaPadraoprivilegiosUsuario);
    }

    onBeforeMount(async () => {
      limparDados();
      const codigoUsuario = obterCodigoRota();
      if (codigoUsuario > 0) {
        await obterUsuario(codigoUsuario);
      }
    });
    function preparaPrivilegios() {
      state.privilegios.forEach((privilegio) => {
        const indexPrivilegioExistente = state.usuario.privilegios.findIndex((c) => c.privilegio === privilegio.identificador);
        if (privilegio.valor) {
          if (indexPrivilegioExistente === -1) {
            const usuarioPrivilegio: IUsuarioPrivilegio = { codigo: 0, codigoUsuario: state.usuario.codigo, privilegio: privilegio.identificador };
            state.usuario.privilegios.push(usuarioPrivilegio);
          }
        } else if (!privilegio.valor) {
          if (indexPrivilegioExistente >= 0) {
            state.usuario.privilegios.splice(indexPrivilegioExistente, 1);
          }
        }
      });
    }
    async function salvar() {
      preparaPrivilegios();
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações do usuário.');
      if (state.usuario.codigo === 0) {
        appBase.resposta = await servicoUsuario.incluir(state.usuario);
      } else {
        appBase.resposta = await servicoUsuario.atualizar(state.usuario);
      }

      apresentarRespostaRedirecionamento('Usuarios');
    }
    return {
      appBase,
      state,
      salvar,
    };
  },
});
