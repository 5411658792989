export interface IDTOUsuarioPrivilegio {
    identificador: string;
    descricao: string;
    valor: boolean;
}

const listaPadraoPrivilegiosUsuario: IDTOUsuarioPrivilegio[] = [
  { identificador: 'CONFIGURACOES', descricao: 'Configurações', valor: false },
  { identificador: 'USUARIOS', descricao: 'Usuários', valor: false },
  { identificador: 'CANDIDATOS', descricao: 'Candidatos', valor: false },
  { identificador: 'SETORES', descricao: 'Setores', valor: false },
  { identificador: 'GRAU-INSTRUCOES', descricao: 'Grau de Instruções', valor: false },
];

export default listaPadraoPrivilegiosUsuario;
